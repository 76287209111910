/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.invoice_from_group {
  margin-bottom: 0.5rem !important;
}

.invoice-from-select-sm {
  padding-top: 0.188rem;
  padding-bottom: 0.188rem;
}

.brand-text-cw {
  color: darkcyan;
  margin-bottom: 2rem !important;
  font-weight: 600 !important;
  letter-spacing: 0.01rem;
  font-size: 1.25rem;
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}

.brand-sub-text-cw {
  color: #22292f;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  font-size: 1.5rem;
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}

.brand-sub-text-cw {
  font-size: 1.2rem;
}

.pdf-width {
  width: 794px;
}

.pdf-card-body {
  padding: 1.5rem 0 0.5rem;
}

.brand-text-cw-invoice {
  font-size: 1.5rem;
  margin-bottom: 0.5rem !important;
}

.brand-text-cw-manifes {
  font-size: 3rem;
  margin-bottom: 0.5rem !important;
  margin-top: 10px!important;
}

.company-name-text-cw-manifes {
  font-size: 1.5rem;
  margin-bottom: 0.5rem !important;
}

.pdf-invoice-spacing {
  margin: 0 !important;
}

.invoice-preview .table th {
  padding: 0.5rem !important;
  font-weight: normal;
  font-size: 0.8rem;
}

.td-invoice-items {
  padding: 0px !important;
}

.td-invoice-items-spacer {
  padding: 0px !important;
  padding-left: 0.5rem !important;
}

.no_bottom_border {
  border-bottom: 0px !important;
}

.order_description {
  padding-left: 5px !important;
  width: 80%;
}

.shipper_consignee_lable {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fafafc;
  border: 1px solid #ebe9f1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.accounting_information {
  height: 50px;
}

.green_back {
  background-color: green !important;
  width: 2px !important;
  padding: 0px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
}

.tem_height {
  height: 120px;
}

.temp_box_height {
  min-height: 200px !important;
}

.temp_box_view_height {
  min-height: 180px !important;
}

.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

.border-table table, .border-table th, .border-table td {
  border: 1px solid grey;
  border-collapse: collapse;
}

.w-35 {
  width: 35%;
}

/** Paper sizes **/
div.A3 .sheet {
  width: 297mm;
  height: 419mm;
}
div.A3.landscape .sheet {
  width: 420mm;
  height: 296mm;
}
div.A4 .sheet {
  width: 210mm;
  height: 296mm;
}
div.A4.landscape .sheet {
  width: 297mm;
  height: 209mm;
}
div.A5 .sheet {
  width: 148mm;
  height: 209mm;
}
div.A5.landscape .sheet {
  width: 210mm;
  height: 147mm;
}
div.letter .sheet {
  width: 216mm;
  height: 279mm;
}
div.letter.landscape .sheet {
  width: 280mm;
  height: 215mm;
}
div.legal .sheet {
  width: 216mm;
  height: 356mm;
}
div.legal.landscape .sheet {
  width: 357mm;
  height: 215mm;
}

/** Padding area **/
.sheet.padding-2mm {
  padding: 2mm;
}
.sheet.padding-x-3mm {
  margin-left: 3mm;
  margin-right: 3mm;
}

.pdfbody.padding-10mm {
  padding: 2mm;
}

.pdfbody.margin-10mm {
  margin: 2mm;
}

.sheet.padding-15mm {
  padding: 15mm;
}
.sheet.padding-20mm {
  padding: 20mm;
}
.sheet.padding-25mm {
  padding: 25mm;
}

.pdfbody table {
  width: 100%;
}


.full_width_row {
  margin-top: 2px;
}

.pdf_header {
  margin-bottom: 10px;
}

.pdf-outline {
  padding: 5px;
}

.billing_summary {
  padding: 0px!important;
  margin: 0px !important;
}

.border-table-billing_summary table, .border-table-billing_summary th, .border-table-billing_summary td {
  border-right: 0px!important;
}

.half_row {
  width: 50%;
}

.dimension_in_cm {
  font-size: 12px;
  font-weight: bolder;
  margin-top: 2px!important;
}

.manifest_pdf_view {
  background-color: #00796B;
  color: white;
  height: 50px;
  font-size: 12px;
}

@media print {
  .no-printme {
    display: none;
  }
  .printme {
    display: block;
  }
}

/** For screen preview **/
@media screen {
  .pdfbody {
    font-size: 11px;
  }
  .sheet {
    background: white;
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
    margin: 5mm auto;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  div.A3.landscape {
    width: 420mm;
  }
  div.A3,
  body.A4.landscape {
    width: 297mm;
  }
  div.A4,
  body.A5.landscape {
    width: 210mm;
  }
  div.A5 {
    width: 148mm;
  }
  @page {
    margin: 0;
  }
  body {
    margin: 0;
    font-size: 11px;
  }
  div.letter,
  body.legal {
    width: 216mm;
  }
  div.letter.landscape {
    width: 280mm;
  }
  div.legal.landscape {
    width: 357mm;
  }

  .pdfbody table {
    width: 210mm;
  }

  .logo-wrapper {
    width: 25mm;
  }

  .basic_info {
    width: 50mm;
  }

  .company_info {
    width: 135mm;
    text-align: center;
  }

  .company_info_table {
    width: 135mm;
    text-align: center;
  }

  .full_width_row {
    margin-top: 1mm;
    width: 210mm;
  }

  .full_width_row .half_row{
    width: 105mm;
  }

  .full_width_row .pw-75{
    width: 158mm;
  }

  .full_width_row .pw-25{
    width: 52mm;
  }

  .full_width {
    width: 100% !important;
  }

  div.A4.landscape {
    height: 210mm;
  }

  

  .shipper_and_consignee .table-bordered > :not(caption) > * > * {
    border-left: none;
    border-right: none;
  }
}
